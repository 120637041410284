<template>
	<div class="d-flex flex-row">
		<!-- 조회 조건 -->
		<search-condtion :ref="'searchCondtion'" :searchBoxCond="searchBoxCond"  :searchOptions="searchOptions" @onChange:timeDsvn="changeTimeDsvn"
			@setDateFormatter="setDateFormatter" />
		<!-- 조회기간 -->
		<date-picker :ref="'datePicker'" :searchBoxCond="searchBoxCond" />
		<!-- 비교대상 -->
		<compare-combo :ref="'compareCombo'" :searchBoxCond="searchBoxCond" @changeCompareType="changeCompareType" />
	</div>
</template>

<script>
import searchCondtion from "./Type1SearchCondtion.vue";
import datePicker from "./Type1DatePicker.vue";
// import compareCombo from "./Type1CompareCombo.vue";
import compareCombo from "../CompareCombo.vue";
import SearchUtils from "@src/views/component/searchBox/analysis/SearchUtils";

export default {
	components: {
		searchCondtion,
		datePicker,
		compareCombo,
	},
	props: ["searchBoxCond", "searchOptions"],
	watch: {},
	data() {
		return {};
	},
	computed: {},
	created() { },
	mounted() { },
	updated() { },
	destroyed() { },
	methods: {
		checkDateRange(param) {
			let message = SearchUtils.searchParamValCheck(param);
			if (message !== "") {
				this.alertNoti(message);
				return false;
			}

			let diffInfo = SearchUtils.dateLengthCheck(param.timeDsvn, param.fromDate, param.toDate);

			message = SearchUtils.wrongDateMessage(param.timeDsvn, diffInfo.diffDay);
			if (message !== "") {
				this.alertNoti(message);
				return false;
			}

			return true;
		},
		// searchbox 조회 조건 event
		changeTimeDsvn(timeDsvn) {

			console.log(`timeDsvn : ${this.searchBoxCond.conditions.timeDsvn} -> ${timeDsvn}`)

			if (this.searchBoxCond.conditions.timeDsvn !== timeDsvn){
				this.searchBoxCond.conditions.timeDsvn = timeDsvn;
				this.searchBoxCond.conditions.compareType = "none";
	
				// 선택된 날짜가 현재보다 크다면 현재 날자로 변경
				let now = new Date();
				if (this.searchBoxCond.datePicker.toDate > now) {
					this.searchBoxCond.datePicker.toDate = now;
				}
	
				let year = this.searchBoxCond.datePicker.toDate.getFullYear();
				let month = this.searchBoxCond.datePicker.toDate.getMonth();
	
				if (timeDsvn === "Log" || timeDsvn === "Min" || timeDsvn === "Quarter" || timeDsvn === "Hour") {
					this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
				}else if (timeDsvn === "Day") {
					this.searchBoxCond.datePicker.fromDate = new Date(year, month, 1);
				} else if (timeDsvn === "Month") {
					this.searchBoxCond.datePicker.fromDate = new Date(year, 0, 1);
				} else if (timeDsvn === "Year") {
					this.searchBoxCond.datePicker.fromDate = new Date(year-9, 0, 1);
				}
	
	
				this.setDateFormatter();
			}			
			
		},
		changeCompareType() {
			this.$refs.datePicker.calcCompareDate();
		},
		setDateFormatter() {
			this.$refs.datePicker.setDateFormatter();
			this.$refs.datePicker.calcDate(this.searchBoxCond.conditions.timeDsvn, new Date());
		},
	},
};
</script>

<style scoped></style>
