<!-- 
에너지원별 소비현황 분석
조회조건 : {구역} {조회유형} {조회기간} {비교대상}
연관API : backEndApi.cspnAnal.zoneEnergySource(param)
특이사항 : 
   - 기본흐름 : 에너지원별 사용량 비교 분석
-->

<template>
  <div class="col-xl-12">
    <header-box :title="`${$t('에너지 소비현황 분석')} > ${$t('에너지원별 소비현황')}`" />

    <div class="searchArea row">
      <div class="col-md-12 innerBox">
        <search-box ref="searchBox" v-if="searchBoxCond" :searchBoxCond="searchBoxCond" @submit:data="onSearchComplete" />
        <!-- <search-box ref="searchBox" v-if="searchBoxCond" :options="searchBoxCond" @submit:data="onSearchComplete" -->
            <!-- @search:data="onSearch" @initSetting="initSetting" > </search-box> -->
      </div>
    </div>
    <card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />
    <chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" />

    <div class="row gridArea">
      <div class="col innerBox" v-if="searchResult && searchResult.data.baseData">
        <base-grid-box ref="gridBox" :options="baseGridOptions" />
      </div>
      <div class="col innerBox" v-if="searchResult && searchResult.data.compData">
        <comp-grid-box ref="gridBox"  :options="compGridOptions" />
      </div>
    </div>

  </div>
</template>

<script>

// Default CSS
import "element-ui/lib/theme-chalk/index.css";

import AnalysisLib from "../AnalysisLib.js";

import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import SearchBox from "./ZoneEnergySourceSearch.vue";

// import CardBox from "@views/component/cardBox/analysis/BasicCard-v2.vue";
import CardBox from "../LightAnalysisCards.vue";
import ChartBox from "../LightAnalysisChart.vue";
import BaseGridBox from "../LightAnalysisDataGrid.vue";
import CompGridBox from "../LightAnalysisDataGrid.vue";

// import xelib from "xelib";
// const { Unit } = xelib.PointBoxV2;

export default {
  name: "ZoneEnergyAnalysis",
  props: ["searchBoxCond", "viewType"],
  components: {
    HeaderBox,
    SearchBox,
    CardBox,
    ChartBox,
    BaseGridBox,
    CompGridBox,
    // YearlyGridBox,
  },

  data() {
    return {
      debug: true,
      searchResult: null,
      cardOptions: [],
      baseGridOptions: null,
      compGridOptions: null,
    };
  },

  created() {
    
  },

  mounted() {
    // CardColor.json으로 사용할 Color를 재정의 함.
    // CardColor를 기반으로 costColor와 peakColor 객체를 생성하고
    // 각각의 객체에 컴포넌트에서 공통으로 사용될 Default Color를 포함시킨다.
    this.searchBoxCond.debug = this.debug;
    this.searchBoxCond.viewType = this.viewType;

    this.debug && console.warn(`### LightAnalysis:${this.viewType} started!!!`);
  },
  
  methods: {

/*
    initSetting() {
      console.warn('initSetting()....................... Called!!!!!!!!!!!');
      this.onSearch();
    },

    onSearch() {
      try {
        console.warn('onSearch()....................... Called!!!!!!!!!!!');
        this.$refs.searchBox.onSearch();
        // this.label = AnalysisLib.getSearchOptionLabel(this.searchBoxCond);
      } catch (e) {
        console.error('ZoneEnergySource Error: ', e);
      }
    },
    */

    onSearchComplete(searchResult) {
      // Refacoring by badblock. 2023
      // Api 호출결과 중 header, baseData, compData 만 사용하고
      // 나머지 항목들은 계산해서 사용해야 함. 향후 제거될 예정임.
      // Summary 항목은 baseData 및 compData로 계산해야 함.
      // 계산된 Summary 항목은 합산값 기준으로 정렬.

      try {
        this.searchResult = searchResult;
        this.searchResult.debug = this.debug;

        // this.label = AnalysisLib.getSearchOptionLabel(this.searchBoxCond);
        this.searchResult = AnalysisLib.makeAnalysisData(searchResult);
        console.log('AnalysisData: ', this.searchResult.data);
        
        // Initialize Component Data Model with Result
        if (this.searchResult) {
          this.initCardBox(this.searchResult);
          this.initGridBox(this.searchResult);
        }

      } catch (e) {
        console.error('ZoneEnergySource Error: ', e);
      }
      
    },

    initCardBox(searchResult) {
      try {        
        this.cardOptions = [];
  
        if (!searchResult) {
          console.warn('searchResult is Empty');
          return;
        }

        let summary = searchResult.data.summary
        // let convertGasVal;
        // let findGas = summary.find(item => item.divCd == "Gas");

        summary.forEach(sumItem => {
          // if(sumItem.divCd == "Total" && findGas) {
          //   convertGasVal = Unit.convert(findGas.baseVal, findGas.unit, "kWh")
          //   sumItem.baseVal = (sumItem.baseVal - findGas.baseVal) + convertGasVal;
          // }
          let option = {
            color: AnalysisLib.getCardColor(this.viewType, sumItem.divCd),
            unit: sumItem.unit,
            compType: searchResult.options.conditions.compareType,
            icon: '',
            divCd: sumItem.divCd,
            title: sumItem.divCdName,
            baseVal: sumItem.baseVal,
            compVal: sumItem.compVal,
            diffRate: sumItem.diffRate,
          };

          this.cardOptions.push(option)
        })

        this.debug && console.log('cardOptions: ', this.cardOptions);

      } catch (e) {
        console.error('ZoneEnergySource Error: ', e);
      }
    },
    initGridBox(searchResult) {
      try {
        this.baseGridOptions = null;
        this.compGridOptions = null;

        if (!searchResult) {
          console.warn('searchResult is Empty');
          return;
        }
        console.log(searchResult);
        
        if (searchResult.data.baseData.length > 0) {
          this.baseGridOptions = {};
          this.baseGridOptions.debug = this.debug;
          this.baseGridOptions.header = searchResult.data.header;
          this.baseGridOptions.data = searchResult.data.baseData;
          this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;
          this.baseGridOptions.download = AnalysisLib.getDownloadFileName('에너지원별소비현황','base',this.searchBoxCond);

          this.debug && console.log('download filename(base):', this.baseGridOptions.download);
        }

        if (searchResult.data.compData && searchResult.data.compData.length > 0) {
          this.compGridOptions = {};
          this.compGridOptions.debug = this.debug;
          this.compGridOptions.header = searchResult.data.header;
          this.compGridOptions.data = searchResult.data.compData;
          this.compGridOptions.dateType = searchResult.options.conditions.timeDsvn;
          this.compGridOptions.download = AnalysisLib.getDownloadFileName('에너지원별소비현황','comp',this.searchBoxCond);
          this.debug && console.log('download filename(comp):', this.compGridOptions.download);
        }
      } catch (e) {
        console.error('ZoneEnergySource Error: ', e);
      }
    },
  },
};
</script>

<style scoped>
.el-table .kbet-el-table th {
  background-color: #f5f7fa;
}
.el-table th,
.el-table tr {
  background-color: #f5f7fa;
}

.comparisonPage {
  background-color: brown;
}
.searchArea {
  margin: 8px 0 8px 0;
  background-color: #00000010;
  border-radius: 8px;
}
 .cardArea, .chartArea, .graphArea, .gridArea {
  margin: 8px 0 8px 0;
}
.innerBox {
  padding: 5px;
}

.dateSelect {
  background-color: cornflowerblue;
  height: 200px;
}
.timeSelect {
  background-color: cyan;
  height: 200px;
}
.cardView {
  background-color: darkkhaki;
  height: 200px;
}
.mixChart {
  background-color: #ffffff;
  height: 300px;
}
.drawLine {
  height: 300px;
}
</style>
