<template>
	<!-- 에너지원 -->
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{$t('에너지원')}}</span>
		</div>
		<div class="p-l-5 p-r-5">
			<select v-if="costType === 'all'" class="form-control" ref="energySourceCode" v-model="energySourceCode" @change="onSmallCodeSet">
				<option value="">{{$t('전체')}}</option>
			</select>
			<select v-else class="form-control" ref="energySourceCode" v-model="energySourceCode" @change="onSmallCodeSet">
				<option v-for="(code, index) in smallCodeList" :key="index" :value="code.smallCode">
					{{ code.smallCodeName }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
import backEndApi from "@src/api/backEndApi.js";

export default {
	components: {},
	props: ["enableTotal", "costType"],
	watch: {
		costType(value) {
			console.log('costType: ', value);
			console.log('smallCodeList: ', this.smallCodeList);
			if (value) {
				if (value === "all") {
					this.energySourceCode = "";
				} else {
					this.energySourceCode = this.smallCodeList? this.smallCodeList[0].smallCode : "";
				}
				// console.log(this.$refs);
			}
		},
	},
	data() {
		return {
			energySourceCode: "",
			smallCodeList: null,
		};
	},
	computed: {},
	created() {
		console.log('EnergySourceCodeComboBox created() called.');
	},
	async mounted() {
		console.log('EnergySourceCodeComboBox mounted() called.');
		await this.loadData();
	},
	updated() { },
	destroyed() { },
	methods: {
		async loadData() {


			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/			
			try {
				console.log('before calling getOprtnSmallCodeList');
				const result = await backEndApi.publicCode.getOprtnSmallCodeList("energySourceCode");
				// this.smallCodeList = result.data.filter((v) => v.etcInfo2 === "Y");
				console.log('getOprtnSmallCodeList call result : ', result);

				// 요금제 관련 조건일 경우 발전원은 제외함
				if (this.costType) {
					this.smallCodeList = result.data.filter(item =>item.etcInfo2 != 'PROD');
				} else {
					this.smallCodeList = result.data;
				}

				if (this.enableTotal) {
					this.smallCodeList.unshift({
						etcInfo2: "Y",
						largeCode: "energySourceCode",
						smallCode: "",
						smallCodeName: "전체",
					});
				}

				if (this.costType !== 'all') {
					this.energySourceCode = this.smallCodeList[0].smallCode;
				}

				this.$emit("ready:data", this.getValue());
			} catch (err) {
				throw err;
			}
		},

		getValue() {
			return this.energySourceCode;
		},

		onSmallCodeSet() {
			this.$emit("ready:data", this.getValue());
		},
	},
};
</script>

<style scoped></style>
