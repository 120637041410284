<template>
	<div>
		<div class="row p-10">
			<!-- 에너지원 -->
			<energy-source-code-combo-box :ref="'energySourceCodeComboBox'" :enableTotal="false" @ready:data="onInitComplete" :costType="costType"> </energy-source-code-combo-box>
			<!-- 분석대상 -->
			<usage-cost-item-combo-box
				v-if="this.waitLoads <= 1"
				:ref="'usageCostItemComboBox'"
				:energySourceCode="$refs.energySourceCodeComboBox.getValue()"
				:defaultUsageItembox="$refs.energySourceCodeComboBox.getValue() === '' ? defaultUsageItembox : undefined"
				@ready:data="onInitComplete" >
				<!-- <template v-slot:customOption="{defaultUsageItembox}" >
					<option>{{defaultUsageItembox}}</option>
				</template> -->
			</usage-cost-item-combo-box>
			<!-- 조회조건, 조회기간, 비교대상 -->
			<search-condition-type-box :ref="'searchConditionTypeBox'" :searchBoxCond="searchBoxCond" :searchOptions="'Hour,Day,Month,Year'" />
			<!-- 조회버튼 -->
			<search-btn :ref="'searchBtn'" @search="onSearch" />
		</div>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	import energySourceCodeComboBox from "../../component/comboBox/analysis/EnergySourceCodeComboBox.vue";
	import usageCostItemComboBox from "../../component/comboBox/analysis/UsageCostItemComboBox.vue";
	import searchConditionTypeBox from "../../component/searchBox/analysis/type1/Type1Box.vue";
	import searchBtn from "../../component/searchBox/analysis/SearchBtn.vue";

	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	export default {
		components: {
			energySourceCodeComboBox,
			usageCostItemComboBox,
			searchConditionTypeBox,
			searchBtn,
		},
		props: ["searchBoxCond", "costType"],
		watch: {
			costType(value) {
				console.log("watch costType :", value);
				this.waitLoads = 2;
				this.$refs.energySourceCodeComboBox.onSmallCodeSet();
			},
		},

		data() {
			return {
				waitLoads: 2,
				defaultUsageItembox: {
					contCapacity: 10,
					costBasicCode: "",
					costDvsnCode: "A001",
					costType: "A003",
					energySourceCode: "",
					ptAddr: "",
					ptName: "전체",
				},
				selectCostInfo: null,
				popupMessages,
			};
		},
		mounted() {
			this.waitLoads = 2;
		},

		methods: {
			async onInitComplete(data) {
				this.waitLoads--;
				this.searchBoxCond.debug && console.debug(`onInitComplete() Initial data loading start.(data:${data}, waits:${this.waitLoads})`);
				//  console.log(data), this.waitLoads;
				if (typeof data !== "string") {
					this.selectCostInfo = data;
				}

				if (this.waitLoads == 0) await this.onSearch();

				// this.waitLoads = 2;
			},

			async onSearch() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/

				let param = null;

				try {
					if (this.searchBoxCond.conditions.timeDsvn != "Yearly") {
						param = {
							timeDsvn: this.searchBoxCond.conditions.timeDsvn,
							ptAddr: this.$refs.usageCostItemComboBox.usageCostItem.ptAddr,
							costType: this.$refs.usageCostItemComboBox.usageCostItem.costType,
							fromDate: this.searchBoxCond.datePicker.fromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
							toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter),
							compFromDate: this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
							compToDate: this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter),
							timeDsvnOpt: this.setTimeDsvnOpt(),
						};
					} else {
						param = {
							timeDsvn: this.searchBoxCond.conditions.timeDsvn,
							ptAddr: this.$refs.usageCostItemComboBox.usageCostItem.ptAddr,
							fromDate: this.searchBoxCond.datePicker.fromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
							toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter),
						};
					}

					let type = null;

					if (param.costType == "A001") {
						type = "Type1";
					} else if (param.costType == "A002") {
						type = "Type2";
					} else if (param.costType == "A003") {
						type = "Type3";
					} else if (param.costType == "A004") {
						type = "Type4";
					}

					let searchType = null;

					let resultData = {};

					if (!this.$refs.searchConditionTypeBox.checkDateRange(param)) {
						return;
					}

					try {
						if (this.$refs.energySourceCodeComboBox.getValue() === "") {
							searchType = "allEnergy";

							const baseResult = await backEndApi.usageCost.totAnalsis(param);

							if (baseResult.data.hasOwnProperty("baseData")) {
								baseResult.data.baseData.forEach((item) => {
									if (typeof item.TotUsage == "number") item.TotUsage = parseFloat(item.TotUsage.toFixed(1));
								});
							}

							resultData = {
								data: baseResult.data,
								selectCode: this.selectCostInfo.energySourceCode || "",
								options: this.searchBoxCond,
								timeDsvn: this.searchBoxCond.conditions.timeDsvn,
								compareType: this.searchBoxCond.conditions.compareType,
								searchType: searchType,
								type: type,
							};
						} else {
							searchType = "singleItem";

							const baseResult = await backEndApi.usageCost.elecAnalsis(param);

							if (baseResult.data.hasOwnProperty("baseData")) {
								baseResult.data.baseData.forEach((item) => {
									if (typeof item.TotUsage == "number") item.TotUsage = parseFloat(item.TotUsage.toFixed(1));
								});
							}

							resultData = {
								data: baseResult.data,
								selectCode: this.selectCostInfo.energySourceCode,
								options: this.searchBoxCond,
								timeDsvn: this.searchBoxCond.conditions.timeDsvn,
								compareType: this.searchBoxCond.conditions.compareType,
								searchType: searchType,
								type: type,
							};
						}
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
							.createBizHandle(this.ERR_KIND.MISSING_ARGUMENT, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
							.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
							.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
							.errHandling(true);
					}

					//Query Compare Data
					if (this.$refs.energySourceCodeComboBox.getValue() === "") {
						if (this.searchBoxCond.conditions.compareType !== "none") {
							try {
								param.fromDate = this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter);
								param.toDate = this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter);
								this.searchBoxCond.debug && console.log("Search Params(comp): ", param);

								const compareResult = await backEndApi.usageCost.totAnalsis(param);
								resultData.data.compData = compareResult.data.baseData;
								this.searchBoxCond.debug && console.log("Search Result: ", resultData);
							} catch (compError) {
								new AppErrorHandler(compError)
									.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
									.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
									.createBizHandle(this.ERR_KIND.MISSING_ARGUMENT, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
									.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
									.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
									.errHandling();

								resultData.data.compData = null;
							}
						}

						param.compFromDate = param.fromDate;
						param.compToDate = param.toDate;
					} else {
						if (this.searchBoxCond.conditions.compareType !== "none") {
							try {
								param.fromDate = this.searchBoxCond.datePicker.compFromDate.format(this.searchBoxCond.datePicker.paramFromFormatter);
								param.toDate = this.searchBoxCond.datePicker.compToDate.format(this.searchBoxCond.datePicker.paramToFormatter);
								this.searchBoxCond.debug && console.log("Search Params(comp): ", param);

								const compareResult = await backEndApi.usageCost.elecAnalsis(param);
								resultData.data.compData = compareResult.data.baseData;
								this.searchBoxCond.debug && console.log("Search Result: ", resultData);
							} catch (compError) {
								new AppErrorHandler(compError)
									.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
									.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
									.createBizHandle(this.ERR_KIND.MISSING_ARGUMENT, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
									.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
									.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_COMPDATA_NO_DATA_POPUP_MESSAGE, this.alertNoti)
									.errHandling();
								resultData.data.compData = null;
							}
						}
					}

					this.$emit("submit:data", resultData);
				} catch (e) {
					console.error("UsageCostSearch Error: ", e);
				}
			},
			setTimeDsvnOpt() {
				return "A001";
				// let timeDsvnOpt = null;

				// switch (this.defaultSearchBoxCond.conditions.timeDsvn) {
				// 	case "Month": {
				// 		switch (this.defaultSearchBoxCond.conditions.compareType) {
				// 			case "previousMonth":
				// 				timeDsvnOpt = "A001";
				// 				break;
				// 		}
				// 		break;
				// 	}

				// 	case "Year": {
				// 		switch (this.defaultSearchBoxCond.conditions.compareType) {
				// 			case "previousYear":
				// 				timeDsvnOpt = "A001";
				// 				break;
				// 		}
				// 		break;
				// 	}
				// }

				// return timeDsvnOpt;
			},
			getLabel() {
				let result = {
					regDtLabel: "",
					baseLabel: "",
					compareLabel: "",
					chartTitle: "",
				};

				if (this.searchBoxCond.conditions.timeDsvn == "Day") {
					result.regDtLabel = "조회시간";
					result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년 MM월 dd일");
					result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년 MM월 dd일");
					result.chartTitle = "시간대별";
				} else if (this.searchBoxCond.conditions.timeDsvn == "Week") {
					result.regDtLabel = "조회요일";
					result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년") + " " + new Date(this.searchBoxCond.datePicker.fromDate).getWeek() + "주차";
					result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년") + " " + new Date(this.searchBoxCond.datePicker.compFromDate).getWeek() + "주차";
					result.searchCondition = "요일별";
					result.chartTitle = "요일별";
				} else if (this.searchBoxCond.conditions.timeDsvn == "Month") {
					result.regDtLabel = "조회기간";
					result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년 MM월");
					result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년 MM월");
					result.chartTitle = "일별";
				} else if (this.searchBoxCond.conditions.timeDsvn == "Year") {
					result.regDtLabel = "조회년월";
					result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년");
					result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년");
					result.chartTitle = "월별";
				} else if (this.searchBoxCond.conditions.timeDsvn == "Yearly") {
					result.regDtLabel = "조회년도";
					result.baseLabel = this.searchBoxCond.datePicker.fromDate.format("yyyy년");
					result.compareLabel = this.searchBoxCond.datePicker.compFromDate.format("yyyy년");
					result.chartTitle = "연도별";
				}

				return result;
			},

			// getUsageCostItemSelectList(energySourceCode) {
			// 	this.selectCode = energySourceCode;

			// 	// this.$refs.usageCostItemComboBox.getUsageCostItemSelectList(energySourceCode);
			// },
		},
	};
</script>

<style scoped>
	.compare {
		margin-top: -5px;
		margin-bottom: 0px;
	}

	.material-switch > input[type="checkbox"] {
		display: none;
	}

	.material-switch > label {
		cursor: pointer;
		height: 0px;
		position: relative;
		width: 40px;
	}

	.material-switch > label::before {
		background: rgb(0, 0, 0);
		box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		content: "";
		height: 16px;
		margin-top: -8px;
		position: absolute;
		opacity: 0.3;
		transition: all 0.4s ease-in-out;
		width: 40px;
	}

	.material-switch > label::after {
		background: rgb(255, 255, 255);
		border-radius: 16px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
		content: "";
		height: 24px;
		left: -4px;
		margin-top: -8px;
		position: absolute;
		top: -4px;
		transition: all 0.3s ease-in-out;
		width: 24px;
	}

	.material-switch > input[type="checkbox"]:checked + label::before {
		background: inherit;
		opacity: 0.5;
	}

	.material-switch > input[type="checkbox"]:checked + label::after {
		background: inherit;
		left: 20px;
	}
</style>
