<!--
  목표분석 : 월별 목표 수립후 일간,월간,년간 누적값으로 도달률을 평가
  -->
<template>
  <div class="row chartArea">
    <div class="col-xl-12 col-lg-6 innerBox" :style="{ padding: '5px' }">
      <div class="col-xl-12 mixChart">
        <area-chart ref="chart" v-if="targetChartOpt.flag" :data="targetChartOpt.data" :options="targetChartOpt.options" 
          :style="{ height: '100%', padding: '15px' }" />
      </div>
    </div>
  </div>
</template>

<script>
import AnalysisLib from '../AnalysisLib';
import AreaChart from "@src/components/vue-chartjs/Charts/LineChart";

// // eslint-disable-next-line no-unused-vars
export default {
  props: ["searchResult"],
  components: {
    AreaChart,
  },
  data() {
    return {
      targetChartOpt: {},
    };
  },
  watch: {
    searchResult() {
      this.reset();
      this.init();
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  updated() {},

  methods: {

    init() {
      this.initChart();
      this.targetChartOpt.flag = true;
      this.setChartOptions();
      this.setChartLabels();
      this.setChartDatasets();

      // this.searchResult.debug && console.debug('UseTargetChart.init() OK');
    },

    reset() {
      this.targetChartOpt = {};
      this.initChart();

      // this.searchResult.debug && console.debug('UseTargetChart.reset() OK');
    },

    initChart() {
      this.targetChartOpt = {
        flag: false,
        data: { labels: null, datasets: null, },
        searchResult: null,
      };

      // this.searchResult.debug && console.debug('UseTargetChart.initChart() OK');
    },

    setChartLabels() {
      this.targetChartOpt.data.labels = [];

      this.searchResult.data.baseData.forEach((data, index) => {
        if (index == 0) {
          this.targetChartOpt.data.labels.push("");
        }
        
        this.targetChartOpt.data.labels.push(global.xe.$t(AnalysisLib.formatDatetime(data.regDt)));
      });

      // this.searchResult.debug && console.debug('UseTargetChart.setChartLabels() OK');
    },

    setChartOptions() {
      this.targetChartOpt.options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
          easing: "easeOutQuart",
        },
        hoverMode: "index",
        legend: {
          display: true,
          align: "end",
          labels: {
            usePointStyle: true,
          },
        },
        title: {
          display: true,
          text: '',
          fontSize: 20,
          fontColor: "#000000",
        },
        hover: {
          intersect: false,
        },
      };

      // this.searchResult.debug && console.debug('UseTargetChart.setChartOptions() OK');
    },

    setChartDatasets() {
      let chartIdx = 0;

      this.targetChartOpt.data.datasets = [];

      this.searchResult.data.header.forEach(header => {
        console.log(header);
        if (header.divCd == "useRate") return;

        chartIdx = this.addChartDatasets(header.divCd, header.divCdName);
        this.setChartColor(this.targetChartOpt.data.datasets[chartIdx-1], header.divCd);
        this.setChartDatasetsToData(this.targetChartOpt.data.datasets[chartIdx-1], header.divCd);
      });
    },

    addChartDatasets(divCd, label) {
      let result = null;

      if (divCd == "targetConsumpt") {
        result = {
          type: "line",
          // backgroundColor: color,
          // borderColor: color,
          borderDash: [3, 3],
          lineTension: 0.5,
          fill: false,
          label: global.xe.$t(label),
          // pointBorderColor: color,
          pointBackgroundColor: "#fff",
          // pointBorderColor: "#aaaaaa",
          // pointBackgroundColor: "#fff",
          pointRadius: 4,
          showLine: true,
          order: 1,
          data: [0],
        };
      } else if(divCd === "totalUseConsumpt"){
        result = {
          type: "bar",
          maxBarThickness: 50,
          label: global.xe.$t(label),
          // backgroundColor: color,
          // borderColor: color,
          borderDash: [0, 0],
          lineTension: 0.5,
          fill: true,
          // pointBorderColor: color,
          // pointBorderColor: "#355288",
          pointBackgroundColor: "#fff",
          pointRadius: 4,
          showLine: true,
          order: 2,
          data: [0],
        };
      }
      return this.targetChartOpt.data.datasets.push(result);
    },

    setChartDatasetsToData(datasets, divCd) {

      // 누적값 계산은 서버에서 제공한 값을 사용.
      // 목표누적 : accumTarget
      // 사용량누적 : accumUsage
      // let accumTarget = 0;
      // let accumUsage = 0;

      if (this.searchResult.data.baseData.length > 0) {

        // 월간/연간은 누적값으로 표시

        // if (this.searchResult.options.conditions.timeDsvn !== "Yearly") {
        //   this.searchResult.data.baseData.forEach((data) => {
        //     if (divCd == "targetConsumpt") {
        //       // 기준데이터 에너지 사용목표 평균값 누적 계산
        //       accumTarget += data[divCd];
        //       datasets.data.push(Number(accumTarget.toFixed(0)));
        //     } else {
        //       if (data[divCd] == "-") {
        //         datasets.data.push(null);
        //       } else {
        //         // 에너지 소비량 누적 계산
        //         accumUsage += data[divCd];
        //         datasets.data.push(Number(accumUsage).toFixed(0));
        //       }
        //     }
        //   });
        // } else {
        //   this.searchResult.data.baseData.forEach((data) => {
        //     if (divCd == "targetConsumpt") {
        //       // 기준데이터 에너지 사용목표 평균값 누적 계산
        //       datasets.data.push(data[divCd]);
        //     } else {
        //       if (data[divCd] == "-") {
        //         datasets.data.push(null);
        //       } else {
        //         // 에너지 소비량 누적 계산
        //         datasets.data.push(data[divCd]);
        //       }
        //     }
        //   });
        // }

        // Summary의 DivCd를 참조하여 Data를 찾도록 변경. 2023.05.12 by badblock
        // targetConsumpt는 더 이상 일별 목표를 제공하지 않고 월목표 값을 전고 월목표 값을 전송함.
        // 일단 divCd: targetConsumpt 인 경우, totalTargetConsumpt에서 데이터를 연결해야 함.
        this.searchResult.data.baseData.forEach((data) => {
          if (divCd == 'targetConsumpt') {
            datasets.data.push( (data[divCd] == "-")? null : data['totalTargetConsumpt'])
          } else {
            datasets.data.push( (data[divCd] == "-")? null : data[divCd])
          }
        });

      }
    },

    setChartColor(datasets, divCd) {
      try {
        datasets.backgroundColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd);
        datasets.borderColor = datasets.backgroundColor;
      } catch (e) {
        console.error('UseTargetChart Error: ', e); 
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.innerBox {
  padding: 5px;
}

.mixChart {
  background-color: #ffffff;
  height: 350px;
}
.drawLine {
  background-color: #ffffff;

  height: 350px;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 7%;
    font-size: 16px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #5562eb;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #54e0c7;
      border-color: #54e0c7;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

// codepen formatting
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

body {
  background-color: #d1d7dc;
  font-family: "Fira Sans", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}
</style>
