<template>
  <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
    <div class="pt-2 p-l-5">
      <span>{{ $t('조회유형') }}</span>
    </div>    
      <div class="p-l-5 p-r-5">
        <div class="btn-group">
          <button v-if="searchOptions.includes('Min')" :ref="'Min'" class="btn btn-white"
            :class="{ active: searchBoxCond.conditions.timeDsvn === 'Min' }"
            @click="onClick('Min')">{{ $t('분') }}</button>
          <button v-if="searchOptions.includes('Quarter')" :ref="'Quarter'" class="btn btn-white"
            :class="{ active: searchBoxCond.conditions.timeDsvn === 'Quarter' }"
            @click="onClick('Quarter')">{{ $t('15분') }}</button>
          <button v-if="searchOptions.includes('Hour')" :ref="'Hour'" class="btn btn-white"
            :class="{ active: searchBoxCond.conditions.timeDsvn === 'Hour' }"
            @click="onClick('Hour')">{{ $t('시간') }}</button>
          <button v-if="searchOptions.includes('Day')" :ref="'Day'" class="btn btn-white"
            :class="{ active: searchBoxCond.conditions.timeDsvn === 'Day' }"
            @click="onClick('Day')">{{ $t('일') }}</button>
          <button v-if="searchOptions.includes('Month')" :ref="'Month'" class="btn btn-white"
            :class="{ active: searchBoxCond.conditions.timeDsvn === 'Month' }"
            @click="onClick('Month')">{{ $t('월') }}</button>
          <button v-if="searchOptions.includes('Year')" :ref="'Year'" class="btn btn-white"
            :class="{ active: searchBoxCond.conditions.timeDsvn === 'Year' }"
            @click="onClick('Year')">{{ $t('연도') }}</button>
        </div>
    </div>
  </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js";

export default {
  name: "Type1SearchCondition",
  components: {},
  props: [ "searchBoxCond", "searchOptions" ],
  watch: {},
  data() {
    return {
    };
  },
  computed: {    
  },
  created() {
    this.searchOptions = this.searchOptions ?? "Min,Quarter,Hour,Day,Month,Year";
  },
  mounted() {
    // this.activeTimeDsvnSetting();
    this.$emit("onChange:timeDsvn", this.searchBoxCond.conditions.timeDsvn);
  },
  updated() {
  },
  destroyed() {
  },
  methods: {
    onClick(type) {
      this.$emit("onChange:timeDsvn", type);
    },
  },
};
</script>

<style scoped></style>