<template>
	<div class="row chartArea">
		<!-- Mixed Chart Area -->
		<div class="col-xl-9 col-lg-6 innerBox" :style="{ padding: '5px' }">
			<div class="col-xl-12 mixChart">
				<mix-chart ref="chart" v-if="usageCostChartData.flag" :chartData="usageCostChartData.data" :options="usageCostChartData.options" :style="{ height: '100%', padding: '15px' }" />
			</div>
		</div>
		<!-- Coverage Chart Area -->
		<div class="col-xl-3 col-lg-6 innerBox">
			<b-tabs>
				<b-tab :title="$t('사용비율')" active>
					<div class="drawLine">
						<doughnut-chart ref="doughnutchart" :data="doughnutChartData.data" :options="doughnutChartData.options" :style="{ paddingTop: '15px', height: '70%', width: '100%' }" />
					</div>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
	import MixChart from "@components/vue-chartjs/Charts/MixChart.js";
	import DoughnutChart from "@components/vue-chartjs/Charts/DoughnutChart.js";
	import AnalysisLib from "../AnalysisLib";

	export default {
		name: "CostChartComponent",
		props: ["searchResult"],
		components: {
			MixChart,
			DoughnutChart,
		},
		data() {
			return {
				usageCostChartData: {},
				doughnutChartData: {},

				environmentalFactorOptions: [
					{ text: "외기온도", value: "outTemp" },
					{ text: "실내온도", value: "inTemp" },
				],
			};
		},
		watch: {
			searchResult() {
				this.reset();
				this.init();
			},
		},
		created() {
			this.init();
		},
		mounted() {},
		updated() {},
		methods: {
			init() {
				this.initChart();
				this.usageCostChartData.flag = true;
				this.setOptions();
				this.setLabels();
				this.setDatasets();
				console.debug("UsageCostChart.init() OK");
			},
			reset() {
				this.usageCostChartData = {};
				this.doughnutChartData = {};
				this.initChart();
				// this.resetEnvFaCheckbox();
				console.debug("UsageCostChart.reset() OK");
			},
			initChart() {
				this.usageCostChartData = {
					flag: false,
					data: { labels: null, datasets: null },
					options: null,
				};
				this.doughnutChartData = {
					flag: false,
					data: { labels: null, datasets: null },
					options: null,
				};
				console.debug("UsageCostChart.initChart() OK");
			},
			setOptions() {
				this.setMixChartOptions();
				this.setDoughnutChartOptions();
				console.debug("UsageCostChart.setOptions() OK");
			},
			setLabels() {
				this.setMixChartLabels();
				this.setDoughnutChartLabels();
				console.debug("UsageCostChart.setLabels() OK");
			},
			setDatasets() {
				this.setMixChartDatasets();
				this.setDoughnutChartDatasets();
				console.debug("UsageCostChart.setDatasets() OK");
			},

			///////////////////////////////////////////////////////////////////
			// MixChart Option
			///////////////////////////////////////////////////////////////////

			// Chart Labels Function.
			initMixChartLabels() {
				this.usageCostChartData.data.labels = [];
			},
			setMixChartLabels() {
				this.initMixChartLabels();
				this.searchResult.data.chartData.forEach((data) => {
					// console.log(AnalysisLib.formatDatetime(data.regDt, this.searchResult.timeDsvn === "Yearly" ? "yearly" : null));
					this.usageCostChartData.data.labels.push(global.xe.$t(AnalysisLib.formatDatetime(data.regDt, this.searchResult.timeDsvn === "Yearly" ? "yearly" : null)));
				});
			},
			resetMixChartLabels() {
				this.usageCostChartData.data.labels = null;
			},

			// Chart Datasets Functions.
			initMixChartDatasets() {
				this.usageCostChartData.data.datasets = [];
			},
			setMixChartDatasets() {
				// MixChart 영역에 요금제에 해당하는 부하요금을 표시 (Stacked Bar)
				// 요금제에 따라 부하요금 기준이 상이하므로 고려해야 함.
				// this.apiData.header[0].divCd : "TotUsage"
				// this.apiData.header[1].divCd : "LowPeak"
				// this.apiData.header[2].divCd : "MidPeak"
				// this.apiData.header[3].divCd : "HighPeak"
				// this.apiData.header[4].divCd : "TotCost"

				try {
					let that = this;
					let chartIdx = 0;

					that.initMixChartDatasets();
					// console.log(that.searchResult.data.datasets);
					const exceptionTot = that.searchResult.data.header.filter((item) => item.divCd !== "TotCost");
					exceptionTot.forEach((header) => {
						if (header.dispOptions.chart === false) return;

						chartIdx = that.addMixChartDatasets("base");
						// that.mixChartData.data.datasets[index*2].label = "당" + target + " 비용";
						that.usageCostChartData.data.datasets[chartIdx - 1].label = global.xe.$t(header.divCdName);
						that.setMixChartColor(that.usageCostChartData.data.datasets[chartIdx - 1], "base", header.divCd);
						that.setMixChartDatasetsToData(that.usageCostChartData.data.datasets[chartIdx - 1], "base", header.divCd);
						// console.log("요금 데이터셋 추가 : ", that.usageCostChartData.data.datasets[chartIdx - 1].label);
						// console.log(that.usageCostChartData.data.datasets[chartIdx - 1]);

						if (that.searchResult.data.compData) {
							chartIdx = that.addMixChartDatasets("compare");
							// that.mixChartData.data.datasets[chartIdx-1].label = "전" + target + " 비용";
							that.usageCostChartData.data.datasets[chartIdx - 1].label = global.xe.$t(header.divCdName) + " " + global.xe.$t("비교");
							that.setMixChartColor(that.usageCostChartData.data.datasets[chartIdx - 1], "compare", header.divCd);
							that.setMixChartDatasetsToData(that.usageCostChartData.data.datasets[chartIdx - 1], "compare", header.divCd);
							console.log("요금 데이터셋 추가 : ", that.usageCostChartData.data.datasets[chartIdx - 1].label);
						}
					});
				} catch (e) {
					console.error("MixedChart Error : ", e);
				}
			},
			resetMixChartDatasets() {
				this.usageCostChartData.data.datasets = null;
			},
			addMixChartDatasets(type) {
				try {
					return this.usageCostChartData.data.datasets.push({
						type: "bar",
						stack: type,
						maxBarThickness: 50,
						label: null,
						xAxisID: "x-axis-0",
						yAxisID: "Left_yAxis",
						order: 2,
						data: [],
						units: [],
					});
				} catch (e) {
					console.error("mixChartData Error : ", e);
				}
			},
			setMixChartDatasetsToData(datasets, key, divCd) {
				console.log(datasets, divCd);
				console.log(this.searchResult.data);

				try {
					let that = this;

					if (that.searchResult.searchType === "allEnergy") {
						if (key === "base") {
							// console.log("that.searchResult.data.chartData!!!!!!!!!!", that.searchResult)
							that.searchResult.data.chartData.forEach((data) => {
								let find = that.searchResult.data.header.find(item => item.divCd == divCd)
								// datasets.order = (key === "base" ? 1 : 2);
								// console.log(data[divCd], divCd)
								datasets.data.push(data[divCd]);
								datasets.units.push(find.unit);
								// console.log(datasets.data)
							});
						} else {
							that.searchResult.data.compData.forEach((data) => {
								datasets.data.push(data[divCd]);
							});
						}
					} else {
						if (key === "base") {
							that.searchResult.data.chartData.forEach((data) => {
								let find = that.searchResult.data.header.find(item => item.divCd == divCd)

								// datasets.order = (key === "base" ? 1 : 2);
								datasets.data.push(data[divCd]);
								datasets.units.push(find.unit);
							});
						} else {
							that.searchResult.data.compData.forEach((data) => {
								datasets.data.push(data[divCd]);
							});
						}
					}
				} catch (e) {
					console.error("MixedChart Error : ", e);
				}
			},

			// Chart Colors Functions.
			setMixChartColor(datasets, key, divCd) {
				try {
					if (key == "base") {
						if (this.searchResult.searchType === "allEnergy") {
							datasets.backgroundColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd, "AllEnergy");
							datasets.borderColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd, "AllEnergy");
						} else {
							datasets.backgroundColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd, "Peak");
							datasets.borderColor = AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, divCd, "Peak");
						}
					} else {
						let color;

						if (this.searchResult.searchType === "allEnergy") {
							color = AnalysisLib.getChartCompareColor(this.searchResult.options.viewType, divCd, "AllEnergy");
						} else {
							color = AnalysisLib.getChartCompareColor(this.searchResult.options.viewType, divCd, "Peak");
						}

						if (color.length <= 7) {
							color = color + "55";
						}
						datasets.backgroundColor = color;
						datasets.borderColor = color;
					}
				} catch (e) {
					console.error("MixedChart Error : ", e);
				}
			},

			// Chart Options Functions.
			setMixChartOptions() {
				let baseLabel = this.searchResult.data.chartData?.map((v) => global.xe.$t(AnalysisLib.formatDatetime(v.regDt, this.searchResult.timeDsvn === "Yearly" ? "yearly" : null)));
				let compLabel = this.searchResult.data.compData?.map((v) => global.xe.$t(AnalysisLib.formatDatetime(v.regDt, this.searchResult.timeDsvn === "Yearly" ? "yearly" : null)));

				this.usageCostChartData.options = AnalysisLib.getCompareChartOptions({ labelSkip: 1 }, baseLabel, compLabel);
			},

			///////////////////////////////////////////////////////////////////
			// DoughnutChart Options
			///////////////////////////////////////////////////////////////////

			// Chart Label Functions.
			initDoughnutChartLabels() {
				this.doughnutChartData.data.labels = [];
			},
			setDoughnutChartLabels() {
				try {
					let that = this;
					that.initDoughnutChartLabels();

					if (that.searchResult.data.hasOwnProperty("header")) {
						that.searchResult.data.header.forEach((data) => {
							if (data.dispOptions.chart === false) return;

							that.doughnutChartData.data.labels.push(global.xe.$t(data.divCdName));
						});
					}
				} catch (e) {
					console.error("DoughnutChart Error : ", e);
				}
			},
			resetDoughnutChartLabels() {
				this.doughnutChartData.data.labels = null;
			},

			// Chart Dataset Functions.
			initDoughnutChartDatasets() {
				this.doughnutChartData.data.datasets = [];
			},
			setDoughnutChartDatasets() {
				try {
					this.initDoughnutChartDatasets();

					this.addDoughnutChartDatasets();
					// let isTot = this.searchResult.data.summary.find((v) => v.divCd.toLowerCase() === "totcost");

					// let tot = isTot ? isTot.baseVal : 0;
					const exceptionTot = this.searchResult.data.header.filter((item) => item.divCd !== "TotCost");
					// const exceptionTot = this.searchResult.data.header;
					exceptionTot.forEach((header) => {
						// console.log("setDoughnutChartDatasets Headers!!!!!!!!!!!!!!!", header)
						if (header.dispOptions.chart === false) return;

						let summary = this.searchResult.data.summary.find((v) => v.divCd === header.divCd);
						console.log("setDoughnutChartDatasets Summary!!!!!!!!!!!!!!!", summary);

						// console.log(tot, summary.baseVal);
						this.setDoughnutChartColor(this.doughnutChartData.data.datasets[0], summary.divCd);
						// if (!isTot) tot += summary.baseVal;

						// this.doughnutChartData.data.datasets[0].data.push(((summary.baseVal / tot) * 100).toFixed(1));
						this.doughnutChartData.data.datasets[0].data.push(summary.baseVal);
						this.doughnutChartData.data.datasets[0].units.push(header.unit);
					});

					// if (this.searchResult.data.hasOwnProperty("summary")) {
					//   console.log(this.searchResult);

					// 	this.searchResult.data.summary.forEach((data) => {
					// 		tot = data.baseVal;
					// 	});

					// 	this.searchResult.data.summary.forEach((data) => {
					// 		this.setDoughnutChartColor(this.doughnutChartData.data.datasets[0], data.divCd);
					// 		this.doughnutChartData.data.datasets[0].data.push(((data.baseVal / tot) * 100).toFixed(1));
					// 	});
					// }
				} catch (e) {
					console.error("DoughnutChart Error : ", e);
				}
			},
			resetDoughnutChartDatasets() {
				this.doughnutChartData.data.datasets = null;
			},
			addDoughnutChartDatasets() {
				try {
					return this.doughnutChartData.data.datasets.push({
						data: [],
						units: [],
						backgroundColor: [],
						borderColor: [],
						borderWidth: 1,
					});
				} catch (e) {
					console.error("DoughnutChart Error : ", e);
				}
			},

			// Chart Color Functions.
			setDoughnutChartColor(datasets, divCd) {
				try {
					if (this.searchResult.searchType === "allEnergy") {
						datasets.backgroundColor.push(AnalysisLib.getCardColor(this.searchResult.options.viewType, divCd, "AllEnergy"));
						datasets.borderColor.push(AnalysisLib.getCardColor(this.searchResult.options.viewType, divCd, "AllEnergy"));
					} else {
						datasets.backgroundColor.push(AnalysisLib.getCardColor(this.searchResult.options.viewType, divCd, "Peak"));
						datasets.borderColor.push(AnalysisLib.getCardColor(this.searchResult.options.viewType, divCd, "Peak"));
					}
				} catch (e) {
					console.error("DoughnutChart Error : ", e);
				}
			},

			// Chart Options Functions.
			setDoughnutChartOptions() {
				this.doughnutChartData.options = AnalysisLib.getSummaryRatioChartOptions();
			},

			///////////////////////////////////////////////////////////////////
			// Environment Factor Options
			///////////////////////////////////////////////////////////////////
			resetEnvFaCheckbox() {
				this.$refs.envFaCheckbox0[0].checked = false;
				this.$refs.envFaCheckbox1[0].checked = false;
			},
			onChangeEnvironmentalFactor(value, checked, id) {
				if (checked) {
					let result = [];

					this.searchResult.data.environmentalFactorData.forEach((data) => {
						result.push(data[value + "Value"]);
					});

					this.addEnvFactordata(result, id);
				} else {
					this.delEnvFactorData(id);
				}
			},
			addEnvFactorData(result, id) {
				this.usageCostChartData.data.datasets.push({
					id: id,
					type: "line",
					label: id === "0" ? "외기온도" : "실내온도",
					xAxisID: "x-axis-0",
					yAxisID: "Right_yAxis",
					order: 1,
					data: result,
					borderColor: AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, id),
					fill: false,
					pointBorderColor: AnalysisLib.getChartBaseColor(this.searchResult.options.viewType, id),
					pointBackgroundColor: "#fff",
				});
			},
			delEnvFactorData(id) {
				this.usageCostChartData.data.datasets.forEach((data, index) => {
					if (data.id == id) {
						this.usageCostChartData.data.datasets.splice(index, 1);
						return;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.innerBox {
		padding: 5px;
	}

	.mixChart {
		background-color: #ffffff;
		height: 388px;
	}
	.drawLine {
		background-color: #ffffff;

		height: 350px;

		display: flex;
		align-items: center;
	}

	.inputGroup {
		background-color: #fff;
		display: block;
		margin: 10px 0;
		position: relative;

		label {
			padding: 12px 7%;
			font-size: 16px;
			width: 100%;
			display: block;
			text-align: left;
			color: #3c454c;
			cursor: pointer;
			position: relative;
			z-index: 2;
			transition: color 200ms ease-in;
			overflow: hidden;

			&:before {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				content: "";
				background-color: #5562eb;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) scale3d(1, 1, 1);
				transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
				opacity: 0;
				z-index: -1;
			}

			&:after {
				width: 32px;
				height: 32px;
				content: "";
				border: 2px solid #d1d7dc;
				background-color: #fff;
				background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
				background-repeat: no-repeat;
				background-position: 2px 3px;
				border-radius: 50%;
				z-index: 2;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				transition: all 200ms ease-in;
			}
		}

		input:checked ~ label {
			color: #fff;

			&:before {
				transform: translate(-50%, -50%) scale3d(56, 56, 1);
				opacity: 1;
			}

			&:after {
				background-color: #54e0c7;
				border-color: #54e0c7;
			}
		}

		input {
			width: 32px;
			height: 32px;
			order: 1;
			z-index: 2;
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			visibility: hidden;
		}
	}

	// codepen formatting
	.form {
		padding: 0 16px;
		max-width: 550px;
		margin: 50px auto;
		font-size: 18px;
		font-weight: 600;
		line-height: 36px;
	}

	body {
		background-color: #d1d7dc;
		font-family: "Fira Sans", sans-serif;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	html {
		box-sizing: border-box;
	}

	code {
		background-color: #9aa3ac;
		padding: 0 8px;
	}
</style>
